function _extends() {
    return (_extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source)Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
        }
        return target;
    }).apply(this, arguments);
}
import { useAbortableEffect } from "$frontend/utils/abort";
export function useScrollRestoration(ref, key) {
    return void 0 === key && (key = "scroll-position"), useAbortableEffect((signal)=>{
        var _history_state, _history_state_key;
        let element = ref.current;
        element && (addEventListener("beforepushState", (e)=>{
            history.replaceState(_extends({}, history.state, {
                [key]: element.scrollTop
            }), "");
        }, {
            signal
        }), element.scrollTop = null != (_history_state_key = null == (_history_state = history.state) ? void 0 : _history_state[key]) ? _history_state_key : 0);
    }), ref;
}
